
const elements = Array.from({ length: 15 }, (it, index) => index),
	sizes = { small: '--width: 55px;', large: '--width: 100px;' };

export default {
	name: 'SpinnerDNA',
	props: {
		spinning: { type: Boolean, default: true },
		size: { type: String, default: 'small' },
	},
	data() {
		return { elements };
	},
	computed: {
		CSSVariables() {
			return sizes[this.size] || sizes.small;
		},
	},
};
