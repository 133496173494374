
const signIn = '/sign-in';

export default {
	name: 'AuthenticatingPage',
	head() {
		return { title: 'Authenticating - Clinikdna' };
	},
	async asyncData({ query }) {
		return { url: query?.r };
	},
	mounted() {
		setTimeout(async () => {
			this.$store.commit('SET_FIRST_ROUTE');
			const user = this.$store.getters['user/GET_USER'];
			let path = signIn;
			if (user || this.url === '/screencast')
				path = this.url || this.$store.getters.GET_ROUTES?.[0]?.root || signIn;
			if (path !== signIn) await this.$store.dispatch('user/WATCH_ROLE');
			await this.$i18n.setLocale(this.$useSettings('general').language || 'en');
			this.$router.replace(path);
		}, 2500);
	},
};
